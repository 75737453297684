<template>
  <div class="h-screen flex items-center justify-center flex-col">
    <img
      :src="system.logo_light_url"
      :alt="system.name"
      class="max-w-sm max-h-32 inline-block"
    />

    <div v-if="!enabled" class="text-center">
      <h1 class="font-bold mb-1 text-lg mt-12">Service Temporarily Unavailable</h1>
      <p class="mb-3">
        Please accept our apologies that our payment processing is currently unavailable. Please try again later.
      </p>
      <p class="mb-6">
        Thank you for your patience.
      </p>
    </div>
    <div v-if="error" class="text-center">
      <h1 class="font-bold mb-1 text-lg mt-12">Payment Unsuccessful</h1>
      <p class="mb-6">
        There was a problem with your payment. Please try again later.
      </p>
      <p>{{ error }}</p>
    </div>
    <div v-else-if="alreadyPaid && $route.query.success" class="text-center">
      <h1 class="font-bold mb-1 text-lg mt-12">Payment Successful</h1>
      <p>Thank you for your purchase. You will shortly receive an email confirming receipt of payment. You may now close this window.</p>
    </div>
    <div v-else-if="alreadyPaid" class="text-center">
      <h1 class="font-bold mb-1 text-lg mt-12">Payment Received</h1>
      <p>Payment has already been received. You may now close this window.</p>
    </div>
    <loader v-else-if="loading" class="mt-12" />
    <div v-else-if="$route.query.manual" class="text-center">
      <h1 class="font-bold mb-1 text-lg mt-12">Payment Cancelled</h1>
      <p class="mb-6">
        Please proceed with payment to begin fulfillment of your marketing pack.
      </p>
      <primary-btn @click="init">Proceed with Payment</primary-btn>
    </div>
  </div>
</template>

<script>
import api from "@/http";
import { loadStripe } from "@stripe/stripe-js";
import PrimaryBtn from "../../components/PrimaryBtn";
import Loader from "../../components/Loader";
import { mapState } from "vuex/dist/vuex.common.js";

export default {
  components: {
    PrimaryBtn,
    Loader,
  },
  data() {
    return {
      enabled: process.env.VUE_APP_PAYMENTS_ENABLED === "true",
      complete: false,
      alreadyPaid: false,
      error: false,
      loading: false,
    };
  },
  computed: {
    ...mapState(['system'])
  },
  created() {
    if (this.enabled && !this.$route.query.manual) {
      this.init();
    }
  },
  methods: {
    init() {
      this.loading = true;
      let paymentEndpoint = this.$route.params.paymentId ?
        `vendor/orders/${this.$route.params.orderId}/payment/${this.$route.params.paymentId}/init` :
        `vendor/orders/${this.$route.params.orderId}/payment/init`

      api
        .get(paymentEndpoint, {
          params: {
            signature: this.$route.query.signature
          },
        })
        .then(
          (response) => {
            this.alreadyPaid = response.data.complete;
            if (this.alreadyPaid) {
              return;
            }

            loadStripe(this.system.stripe_public_key).then((stripe) => {
              stripe.redirectToCheckout({
                sessionId: response.data.session_id,
              });
            });
          },
          (error) => {
            this.error = error.response.data.message;
          }
        );
    },
  },
};
</script>